import React, { useReducer, useState, useRef } from 'react';
import './Ach.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banklist from './Banklist';
import AchPdf from './AchPdf';
import './AchPdf.css';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Ach = () => {
    const [formData, setFormData] = useState({
        accType: '',
        cusName: '',
        accNo: [],
        fromDate: [],
        bankName: '',
        toDate: [],
        ifscCode: [],
        reference: '',
        amount: '',
        phoneNumber: '',
        amount_words: '',
        reference_2: ''
    });
    const [errors, setErrors] = useState({});
    const [ifscerror, setIfscerror] = useState(false);
    const [amounterror, setamounterror] = useState(false);
    const pdfref = useRef();

    const handleDownloadPDF = () => {
        const pdfContent = document.getElementById('pdf_content');
        var opt = {
            filename: 'ACHForm',
            margin: 1,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 4 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape', dpi: 300 }
        }
        html2pdf().set(opt).from(pdfContent).save();
    };
    const convertNumberToWords = (amount) => {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        const currencyName = 'Rupees';
        const onlyWord = 'only';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            for (var i = 0; i < 9; i++) {
                var value = (i == 0 || i == 2 || i == 4 || i == 7) ? n_array[i] * 10 : n_array[i];
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
            words_string += ' ' + currencyName + ' ' + onlyWord;
        }
        return words_string;
    }
    const dateSplit = (date) => {
        if (!date || date.trim() === "") {
            return []; // Return an empty array if the date is empty or null
        }
        var formateddate = handleformatDate(date);
        formateddate = formateddate.split("/");
        var strdate = formateddate.join('');
        var arrdate = strdate.split("");
        return arrdate;
    }
    const handleformatDate = (dateString) => {
        const parts = dateString.split("-");
        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
        return formattedDate;
    };
    const handleLetterRestriction = (e) => {
        const char_code = e.which ? e.which : e.keycode;
        if (char_code > 31 && (char_code < 48 || char_code > 57) && char_code !== 8 || (e.shiftKey)) {
            e.preventDefault();
        }
    }
    const handleAccountNumber = (acc) => {
        let account_no = acc.split("");
        if (account_no.length < 20) {
            const remain_length = 20 - account_no.length;
            for (let i = 0; i < remain_length; i++) {
                account_no.push('');
            }
        }
        return account_no;
    }
    const handleIfscCode = (e) => {
        var value = e.target.value;
        if (value.length !== 11) {
            setIfscerror(prev => true);
        }
        else {
            setIfscerror(prev => false);
        }
    }
    const handleAmountValidate = (e) => {
        var value = e.target.value;
        if (value <= 0) {
            setamounterror(prev => true);
        }
        else {
            setamounterror(prev => false);
        }
    }
    const handlecancelForm = () => {
        window.location.reload();
    }
    const handlSubmitForm = () => {
        //alert('submit');
        var acctype = document.getElementById('acc_type').value;
        var cusName = document.getElementById('cus_name').value;
        var acc_no = document.getElementById('acc_no').value;
        var bank_name = document.getElementById('bank_name').value;
        var bank_amount = document.getElementById('bank_amount').value;
        var bank_ifsc = document.getElementById('bank_ifsc').value;
        var fromdate = document.getElementById('from_date').value;
        var phoneNumber = document.getElementById('ph_number').value;
        var todate = document.getElementById('to_date').value;
        var reference = document.getElementById('bank_reference').value;
        var reference_2 = document.getElementById('bank_reference_2').value;


        if (acctype === '' || cusName === '' || acc_no === '' || bank_name === '' || bank_amount === '' || bank_ifsc === '') {
            setErrors({});
            if (acctype === '') {
                setErrors(prevErrors => ({ ...prevErrors, accType: true }));
            }
            if (cusName === '') {
                setErrors(prevErrors => ({ ...prevErrors, cusName: true }));
            }
            if (acc_no === '') {
                setErrors(prevErrors => ({ ...prevErrors, accNo: true }));
            }
            if (bank_amount === '') {
                setErrors(prevErrors => ({ ...prevErrors, bankAmount: true }));
            }
            if (bank_ifsc === '') {
                setErrors(prevErrors => ({ ...prevErrors, bankIfsc: true }));
            }
            if (bank_name === '') {
                setErrors(prevErrors => ({ ...prevErrors, bankName: true }));
            }
        }
        else {
            var amount_words = convertNumberToWords(bank_amount);
            var from_date = dateSplit(fromdate);
            var to_date = dateSplit(todate);
            var accNo = handleAccountNumber(acc_no);
            var ifsc = bank_ifsc.split("");
            setFormData(prevFormData => ({
                ...prevFormData,
                accType: acctype,
                cusName: cusName,
                accNo: accNo,
                bankName: bank_name,
                amount: bank_amount,
                ifscCode: ifsc,
                phoneNumber: phoneNumber,
                fromDate: from_date,
                toDate: to_date,
                reference: reference,
                amount_words: amount_words,
                reference_2: reference_2
            }));

            const hasErrors = Object.keys(errors).some(key => errors[key]);
            //  console.log(hasErrors)
            if (hasErrors === false) {
                // alert(1)
                const pdfContent = document.getElementById('submitted_form');
                var opt = {
                    filename: cusName,
                    margin: 1,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 4 },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape', dpi: 300 }
                }
                html2pdf().set(opt).from(pdfContent).save();
            }
        }

    }


    return (
        <div>
            <div className='container mt-4'>
                <div>
                    <h2 className='content_head_name'>ACH Form</h2>
                </div>
                <div className='ach_form_body'>
                    <div className='row'>
                        <div className="col-md-6 col-12">
                            <label className='form-label acc_type form_label_txt'>Type of Account<span className='mandatory_fields_val'>*</span></label>
                            <select name="" id="acc_type" className='form-select form_select_txt'>
                                <option value="">Select</option>
                                <option value="Savings Bank">Savings Bank</option>
                                <option value="Current Account">Current Account</option>
                                <option value="Cash Credit">Cash Credit</option>
                                <option value="Overdraft OD">Overdraft OD</option>i
                                <option value="SB – NRE">SB – NRE</option>
                                <option value="SB – NRO">SB – NRO</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors && errors.accType === true ? (<p className='error_message_indicate'>This Field is Required</p>) : ('')}
                        </div>
                        <div className="col-md-6 col-12">
                            <label className='form-label cus_name form_label_txt'>Customer Name<span className='mandatory_fields_val'>*</span></label>
                            <input type="text" className='form-control form_control_text' id='cus_name' autoComplete='off' />
                            {errors && errors.cusName === true ? (<p className='error_message_indicate'>This Field is Required</p>) : ('')}
                        </div>
                    </div>
                    <div className="row space_btw_fields">
                        <div className="col-md-6 col-12">
                            <label htmlFor="acc_no" className='form-label form_label_txt'>Bank Account Number<span className='mandatory_fields_val'>*</span></label>
                            <input type="text" className='form-control form_control_text' id='acc_no' autoComplete='off' maxLength={20} />
                            {errors && errors.accNo === true ? (<p className='error_message_indicate'>This Field is Required</p>) : ('')}
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="from_date" className='form-label form_label_txt'>From Date</label>
                            <input type="date" name="" id="from_date" className='form-control form_control_text' autoComplete='off' />
                        </div>
                    </div>
                    <div className="row space_btw_fields">
                        <div className="col-md-6 col-12">
                            <label htmlFor="bank_name" className='form-label form_label_txt'>Bank Name<span className='mandatory_fields_val'>*</span></label>
                            <Banklist />
                            {errors && errors.bankAmount === true ? (<p className='error_message_indicate'>This Field is Required</p>) : ('')}
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="to_date" className='form-label form_label_txt'>To Date</label>
                            <input type="date" name="" id="to_date" className='form-control form_control_text' />
                        </div>
                    </div>
                    <div className="row space_btw_fields">
                        <div className="col-md-6 col-12">
                            <label htmlFor="bank_ifsc" className='form-label form_label_txt'>IFSC Code<span className='mandatory_fields_val'>*</span></label>
                            <input type="text" className='form-control form_control_text' id='bank_ifsc' maxLength={11} autoComplete='off' onBlur={(e) => handleIfscCode(e)} />
                            {errors && errors.bankIfsc === true ? (<p className='error_message_indicate'>This Field is Required</p>) : ('')}
                            {ifscerror && ifscerror === true ? (<p className='error_message_indicate'>IFSC code must be exactly 11 characters.</p>) : ('')}
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="bank_reference" className='form-label form_label_txt'>Reference 1</label>
                            <input type="text" id='bank_reference' className='form-control form_control_text' autoComplete='off' />
                        </div>
                    </div>
                    <div className="row space_btw_fields">
                        <div className="col-md-6 col-12">
                            <label htmlFor="bank_amount" className='form-label form_label_txt'>Amount<span className='mandatory_fields_val'>*</span></label>
                            <input type="text" className='form-control form_control_text' id='bank_amount' maxLength={9} autoComplete='off' onKeyDown={(e) => { handleLetterRestriction(e) }} onChange={(e) => { handleAmountValidate(e) }} />
                            {errors && errors.bankAmount === true ? (<p className='error_message_indicate'>This Field is Required</p>) : ('')}
                            {amounterror && amounterror === true ? (<p className='error_message_indicate'>Amount should be greater than 0</p>) : ('')}
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="bank_reference_2" className='form-label form_label_txt'>Reference 2</label>
                            <input type="text" id='bank_reference_2' className='form-control form_control_text' autoComplete='off' />
                        </div>
                    </div>
                    <div className="row space_btw_fields">
                        <div className="col-md-6 col-12">
                            <label htmlFor="ph_number" className='form-label form_label_txt'>Phone Number</label>
                            <input type="text" className='form-control form_control_text' id='ph_number' autoComplete='off' maxLength={15} onKeyDown={(e) => { handleLetterRestriction(e) }} />
                        </div>
                    </div>
                    <div className='ach_actions'>
                        <button type='button' className='btn bt-success submit_btn' id='submit_form' onClick={handlSubmitForm}>Submit</button>
                        <button type="button" className='btn btn-secondary cancel_btn' id='cancel_form' onClick={handlecancelForm}>Cancel</button>
                        <span className='download_file_link' id='file_download' onClick={handleDownloadPDF}>Download File</span>
                    </div>
                </div>
            </div>
            <div style={{ display: 'none' }}>
                <div id='pdf_content'>
                    <AchPdf />
                </div>
            </div>
            <div style={{ display: 'none' }}>
                <div id="submitted_form">
                    <AchPdf formdata={formData} />
                </div>
            </div>
        </div>
    )
}

export default Ach

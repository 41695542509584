import React, { useEffect, useState } from 'react';
import './AchPdf.css';
import 'bootstrap/dist/css/bootstrap.min.css';



const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${padZero(day)}/${padZero(month)}/${year}`;
};

const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
};


const AchPdf = ({ formdata }) => {
    //console.log(formdata);
    // console.log(formdata[0].accNo);
    var today = formatDate(new Date());
    var date = today.split("/")
    var strdate = date.join('');
    var arrdate = strdate.split("");
    return (
        <div>
            {formdata && Object.keys(formdata).length > 0 ? (
                <div>
                    <div className="panel panelPdf">
                        <div className="canvas_div_pdf" style={{ fontSize: '15px', fontWeight: 500 }}>
                            <table className="table_dt" style={{ fontFamily: 'Arial', borderCollapse: 'collapse' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ border: 'none' }} className="aligncolumnIndent">UMRN&nbsp;</td>
                                        <td className=" UMRNnumberbox umrn0"></td>
                                        <td className=" UMRNnumberbox umrn1"></td>
                                        <td className=" UMRNnumberbox umrn2"></td>
                                        <td className=" UMRNnumberbox umrn3"></td>
                                        <td className=" UMRNnumberbox umrn4"></td>
                                        <td className=" UMRNnumberbox umrn5"></td>
                                        <td className=" UMRNnumberbox umrn6"></td>
                                        <td className=" UMRNnumberbox umrn7"></td>
                                        <td className=" UMRNnumberbox umrn8"></td>
                                        <td className=" UMRNnumberbox umrn9"></td>
                                        <td className=" UMRNnumberbox umrn10"></td>
                                        <td className=" UMRNnumberbox umrn11"></td>
                                        <td className=" UMRNnumberbox umrn12"></td>
                                        <td className=" UMRNnumberbox umrn13"></td>
                                        <td className=" UMRNnumberbox umrn14"></td>
                                        <td className=" UMRNnumberbox umrn15"></td>
                                        <td className=" UMRNnumberbox umrn16"></td>
                                        <td className=" UMRNnumberbox umrn17"></td>
                                        <td className=" UMRNnumberbox umrn18"></td>
                                        <td className=" UMRNnumberbox umrn19"></td>
                                        <td style={{ paddingLeft: '315px', border: 'none' }}>Date&nbsp;</td>
                                        {arrdate && arrdate.map((digit, index) => (
                                            <td key={index} className={"SmallBoxDate Firstdate" + index}>{digit}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            {/* <table style={{ marginTop: '20px' }}>
                                <tbody style={{width : '100vh'}}>
                                    <tr>
                                        <td className="aligncolumnIndent" style={{ width: '190px' }}>Sponsor Bank Code&nbsp;</td>
                                        <td className="table-bordered" style={{ width: '400px', height: '34px', textAlign: 'center' }}>CHAS0INBX01</td>
                                        <td style={{ paddingLeft: '230px' }}></td>
                                        <td style={{ paddingLeft: '5px' }}><input type="checkbox" className="form-control-lg" defaultChecked /><span style={{ paddingLeft: '5px', marginTop: '10px' }}>Create</span></td>
                                        <td style={{ paddingLeft: '5px' }}><input type="checkbox" className="form-control-lg" /><span style={{ paddingLeft: '5px', marginTop: '10px' }}>Modify</span></td>
                                        <td style={{ paddingLeft: '5px' }}><input type="checkbox" className="form-control-lg" /><span style={{ paddingLeft: '5px', marginTop: '10px' }}>Cancel</span></td>
                                    </tr>
                                </tbody>
                            </table> */}
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent" style={{ width: '140px' }}>Sponsor Bank Code&nbsp;</td>
                                        <td className="table-bordered" style={{ width: '387px', height: '26px', textAlign: 'center' }}>CHAS0INBX01</td>
                                        <td style={{ paddingLeft: '237px' }}></td>
                                        <td className='type_of_form' colSpan="3">
                                            <div style={{ display: 'flex' }}>
                                                <input type="checkbox" className="" defaultChecked /><span style={{ paddingLeft: '5px' }}>Create</span>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <input type="checkbox" className="" /><span style={{ paddingLeft: '5px' }}>Modify</span>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <input type="checkbox" className="" /><span style={{ paddingLeft: '5px' }}>Cancel</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent">Utility Code</td>
                                        <td className="table-bordered" style={{ width: '25%', height: '26px', textAlign: 'center' }}>NACH00000000024199</td>
                                        <td style={{ paddingLeft: '16px' }}>I/We authorize</td>
                                        <td className="table-bordered" style={{ width: '56%', height: '26px', textAlign: 'center' }}>MERCEDES&#8211;BENZ&nbsp;FINANCIAL&nbsp;SERVICES&nbsp;INDIA&nbsp;PVT.&nbsp;LTD.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent" style={{ width: '100px' }}>To Debit (tick)&nbsp;</td>
                                        <td className="table-bordered accounttype" style={{ width: '25%', height: '28px', textAlign: 'left', paddingLeft: '10px' }}>{formdata.accType}</td>
                                        <td style={{ paddingLeft: '10px', width: '145px' }}>Bank Account Number&nbsp;</td>
                                        {formdata && formdata.accNo && formdata.accNo.map((digit, index) => (
                                            <td key={index} className={"table-bordered Accountnumberbox Accountnumber_" + index}>{digit}</td>
                                        ))}
                                        {/* <td className="table-bordered Accountnumberbox Accountnumber_0"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_1"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_2"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_3"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_4"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_5"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_6"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_7"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_8"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_9"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_10"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_11"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_12"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_13"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_14"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_15"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_16"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_17"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_18"></td>
                                        <td className="table-bordered Accountnumberbox Accountnumber_19"></td> */}

                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent" style={{ width: '120px' }}>With Bank&nbsp;</td>
                                        <td className="table-bordered withbank" style={{ width: '60%', height: '25px', textAlign: 'left', paddingLeft: '10px' }}>{formdata.bankName}</td>
                                        <td style={{ paddingLeft: '5px', width: '40px' }}>IFSC&nbsp;</td>
                                        {formdata && formdata.ifscCode && formdata.ifscCode.map((digit, index) => (
                                            <td key={index} className={"table-bordered IfscCodeBox IfscCode_" + index}>{digit}</td>
                                        ))}
                                        {/* <td className="table-bordered IfscCodeBox IfscCode_0" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_1" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_2" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_3" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_4" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_5" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_6" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_7" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_8" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_9" style={{ height: '25px' }}></td>
                                        <td className="table-bordered IfscCodeBox IfscCode_10" style={{ height: '25px' }}></td> */}
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent">An amount of Rupees&nbsp;</td>
                                        <td className="table-bordered amountInwords" style={{ width: '58%', height: '26px', textAlign: 'left', paddingLeft: '10px' }}>{formdata.amount_words}</td>
                                        <td style={{ paddingLeft: '21px' }}></td>
                                        <td className="table-bordered amount" style={{ width: '25%', height: '26px', textAlign: 'left', paddingLeft: '10px' }}>{'Rs.' + formdata.amount + '/-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr className='row_style'>
                                        <td style={{ paddingLeft: '11px', textDecoration: 'underline solid black' }}>Debit Type</td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Fixed Amount</span></td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" defaultChecked className="" /><span className='row_td_span_style'>Maximum Amount</span></td>
                                        <td style={{ paddingLeft: '7px', textDecoration: 'underline solid black' }}>Frequency</td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Monthly</span></td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Quarterly</span></td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Half Yearly</span></td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Yearly</span></td>
                                        <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" defaultChecked className="" /><span className='row_td_span_style'>As & When Presented</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent" style={{ width: '90px' }}>Reference 1&nbsp;</td>
                                        <td className="table-bordered reference1 reference" >{formdata.reference}</td>
                                        <td style={{ paddingLeft: '15px', width: '90px' }}>Reference 2&nbsp;</td>
                                        <td className="table-bordered reference2 reference" style={{ width: '40.5%', height: '28px', textAlign: 'left', paddingLeft: '10px' }}>{formdata.reference_2}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent">
                                            <p align="justify" style={{ width: '100%', letterSpacing: '0px', lineHeight: '16px', fontSize: '14px', wordSpacing: '0px' }}>
                                                1) I agree for the debit of mandate processing charges by the bank whom I am authorizing to debit my account as per latest schedule of charges of the bank.2) This is to confirm that the declaration has been carefully read, understood & made by me/us. I am authorizing the user entity/Corporate to debit my account,based on the instruction as agreed and signed by me. <br /> 3) I have understood that I am authorized to cancel/amend this mandate by appropriately communicating the cancellation/amendment request to the used entity/corporate or the bank where I have authorized the debit.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tabledate" style={{ marginTop: '-6px' }}>
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent">FROM</td>
                                        <td style={{ paddingLeft: '5px' }}></td>
                                        {formdata && formdata.fromDate.length > 0 ? (
                                            formdata.fromDate.slice(0, 2).map((digit, index) => (
                                                <td key={index} className={"table-bordered SmallBoxDate Fromdate_" + index} style={{ height: '27px' }}>{digit}</td>
                                            ))
                                        ) : (
                                            <>
                                                <td className="table-bordered SmallBoxDate Fromdate_0" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_1" style={{ height: '27px' }}></td>
                                            </>
                                        )}
                                        <td style={{ paddingLeft: '5px' }}></td>
                                        {formdata && formdata.fromDate.length > 2 ? (
                                            formdata.fromDate.slice(2, 4).map((digit, index) => (
                                                <td key={index} className={"table-bordered SmallBoxDate Fromdate_" + (index + 2)} style={{ height: '27px' }}>{digit}</td>
                                            ))
                                        ) : (
                                            <>
                                                <td className="table-bordered SmallBoxDate Fromdate_2" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_3" style={{ height: '27px' }}></td>
                                            </>
                                        )}
                                        <td style={{ paddingLeft: '5px' }}></td>
                                        {formdata && formdata.fromDate.length > 4 ? (
                                            formdata.fromDate.slice(4).map((digit, index) => (
                                                <td key={index} className={"table-bordered SmallBoxDate Fromdate_" + (index + 4)} style={{ height: '27px' }}>{digit}</td>
                                            ))
                                        ) : (
                                            <>
                                                <td className="table-bordered SmallBoxDate Fromdate_4" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_5" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_6" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_7" style={{ height: '27px' }}></td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>

                            <table className="tabledate">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent">TO</td>
                                        <td style={{ paddingLeft: '26px' }}></td>
                                        {formdata && formdata.toDate.length > 0 ? (
                                            formdata.toDate.slice(0, 2).map((digit, index) => (
                                                <td key={index} className={"table-bordered SmallBoxDate Fromdate_" + index} style={{ height: '27px' }}>{digit}</td>
                                            ))
                                        ) : (
                                            <>
                                                <td className="table-bordered SmallBoxDate Fromdate_0" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_1" style={{ height: '27px' }}></td>
                                            </>
                                        )}
                                        <td style={{ paddingLeft: '5px' }}></td>
                                        {formdata && formdata.toDate.length > 2 ? (
                                            formdata.toDate.slice(2, 4).map((digit, index) => (
                                                <td key={index} className={"table-bordered SmallBoxDate Fromdate_" + (index + 2)} style={{ height: '27px' }}>{digit}</td>
                                            ))
                                        ) : (
                                            <>
                                                <td className="table-bordered SmallBoxDate Fromdate_2" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_3" style={{ height: '27px' }}></td>
                                            </>
                                        )}
                                        <td style={{ paddingLeft: '5px' }}></td>
                                        {formdata && formdata.toDate.length > 4 ? (
                                            formdata.toDate.slice(4).map((digit, index) => (
                                                <td key={index} className={"table-bordered SmallBoxDate Fromdate_" + (index + 4)} style={{ height: '27px' }}>{digit}</td>
                                            ))
                                        ) : (
                                            <>
                                                <td className="table-bordered SmallBoxDate Fromdate_4" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_5" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_6" style={{ height: '27px' }}></td>
                                                <td className="table-bordered SmallBoxDate Fromdate_7" style={{ height: '27px' }}></td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin">
                                <tbody>
                                    <tr>
                                        <td className="aligncolumnIndent" style={{ width: '55px' }}>
                                        </td>
                                        <td style={{ fontSize: '13px', paddingBottom: '7px', fontWeight: '500' }}>
                                            Maximum period of validity of this mandate <br /> is 40 years only
                                        </td>
                                        <td className="aligncolumnIndent">&nbsp;</td>
                                        <td className="Phone-Pull-tdleft" style={{ width: '25%', textDecoration: 'underline solid #808080', color: '#000 !important' }}> Signature Primary Account Holder </td>
                                        <td className="Phone-Pull-tdleft" style={{ width: '20%', textDecoration: 'underline solid #808080', color: '#000 !important' }}> Signature of Account Holder </td>
                                        <td className="Phone-Pull-tdleft" style={{ width: '20%', textDecoration: 'underline solid #808080', color: '#000 !important' }}> Signature of Account Holder </td>
                                    </tr>
                                    <tr>
                                        <td className="aligncolumnIndent">Phone No.</td>
                                        <td className="table-bordered Phone-td-style phoneno" style={{ paddingLeft: '10px' }}>{formdata.phoneNumber}</td>
                                        <td className="aligncolumnIndent">&nbsp;</td>

                                        <td className="bankrecord-pull-left name1">
                                            <b style={{ color: 'black' }}>1. <span style={{ textDecoration: 'underline' }}>{formdata.cusName}</span></b>

                                        </td>
                                        <td className="bankrecord-pull-left name2">
                                            <b style={{ color: 'black' }}>2. ___________________</b>
                                        </td>
                                        <td className="bankrecord-pull-left name3">
                                            <b style={{ color: 'black' }}>3. ___________________</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tablemargin" style={{ marginTop: '0px' }}>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="panel panelPdf">
                    <div className="canvas_div_pdf" style={{ fontSize: '15px', fontWeight: 500 }}>
                        <table className="table_dt" style={{ fontFamily: 'Arial', borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr>
                                    <td style={{ border: 'none' }} className="aligncolumnIndent">UMRN&nbsp;</td>
                                    <td className=" UMRNnumberbox umrn0"></td>
                                    <td className=" UMRNnumberbox umrn1"></td>
                                    <td className=" UMRNnumberbox umrn2"></td>
                                    <td className=" UMRNnumberbox umrn3"></td>
                                    <td className=" UMRNnumberbox umrn4"></td>
                                    <td className=" UMRNnumberbox umrn5"></td>
                                    <td className=" UMRNnumberbox umrn6"></td>
                                    <td className=" UMRNnumberbox umrn7"></td>
                                    <td className=" UMRNnumberbox umrn8"></td>
                                    <td className=" UMRNnumberbox umrn9"></td>
                                    <td className=" UMRNnumberbox umrn10"></td>
                                    <td className=" UMRNnumberbox umrn11"></td>
                                    <td className=" UMRNnumberbox umrn12"></td>
                                    <td className=" UMRNnumberbox umrn13"></td>
                                    <td className=" UMRNnumberbox umrn14"></td>
                                    <td className=" UMRNnumberbox umrn15"></td>
                                    <td className=" UMRNnumberbox umrn16"></td>
                                    <td className=" UMRNnumberbox umrn17"></td>
                                    <td className=" UMRNnumberbox umrn18"></td>
                                    <td className=" UMRNnumberbox umrn19"></td>
                                    <td style={{ paddingLeft: '315px', border: 'none' }}>Date&nbsp;</td>
                                    <td className=" SmallBoxDate Firstdate0"></td>
                                    <td className=" SmallBoxDate Firstdate1"></td>
                                    <td className=" SmallBoxDate Firstdate2"></td>
                                    <td className=" SmallBoxDate Firstdate3"></td>
                                    <td className=" SmallBoxDate Firstdate4"></td>
                                    <td className=" SmallBoxDate Firstdate5"></td>
                                    <td className=" SmallBoxDate Firstdate6"></td>
                                    <td className=" SmallBoxDate Firstdate7"></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent" style={{ width: '140px' }}>Sponsor Bank Code&nbsp;</td>
                                    <td className="table-bordered" style={{ width: '387px', height: '26px', textAlign: 'center' }}>CHAS0INBX01</td>
                                    <td style={{ paddingLeft: '237px' }}></td>
                                    <td className='type_of_form' colSpan="3">
                                        <div style={{ display: 'flex' }}>
                                            <input type="checkbox" className="" defaultChecked /><span style={{ paddingLeft: '5px' }}>Create</span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <input type="checkbox" className="" /><span style={{ paddingLeft: '5px' }}>Modify</span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <input type="checkbox" className="" /><span style={{ paddingLeft: '5px' }}>Cancel</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent">Utility Code</td>
                                    <td className="table-bordered" style={{ width: '25%', height: '26px', textAlign: 'center' }}>NACH00000000024199</td>
                                    <td style={{ paddingLeft: '16px' }}>I/We authorize</td>
                                    <td className="table-bordered" style={{ width: '56%', height: '26px', textAlign: 'center' }}>MERCEDES&#8211;BENZ&nbsp;FINANCIAL&nbsp;SERVICES&nbsp;INDIA&nbsp;PVT.&nbsp;LTD.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent" style={{ width: '100px' }}>To Debit (tick)&nbsp;</td>
                                    <td className="table-bordered accounttype" style={{ width: '25%', height: '28px', textAlign: 'left', paddingLeft: '10px' }}></td>
                                    <td style={{ paddingLeft: '10px', width: '145px' }}>Bank Account Number&nbsp;</td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_0"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_1"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_2"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_3"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_4"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_5"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_6"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_7"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_8"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_9"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_10"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_11"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_12"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_13"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_14"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_15"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_16"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_17"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_18"></td>
                                    <td className="table-bordered Accountnumberbox Accountnumber_19"></td>

                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent" style={{ width: '120px' }}>With Bank&nbsp;</td>
                                    <td className="table-bordered withbank" style={{ width: '60%', height: '25px', textAlign: 'left', paddingLeft: '10px' }}></td>
                                    <td style={{ paddingLeft: '5px', width: '40px' }}>IFSC&nbsp;</td>
                                    <td className="table-bordered IfscCodeBox IfscCode_0" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_1" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_2" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_3" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_4" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_5" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_6" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_7" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_8" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_9" style={{ height: '25px' }}></td>
                                    <td className="table-bordered IfscCodeBox IfscCode_10" style={{ height: '25px' }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent">An amount of Rupees&nbsp;</td>
                                    <td className="table-bordered amountInwords" style={{ width: '58%', height: '26px', textAlign: 'left', paddingLeft: '10px' }}></td>
                                    <td style={{ paddingLeft: '21px' }}></td>
                                    <td className="table-bordered amount" style={{ width: '25%', height: '26px', textAlign: 'left', paddingLeft: '10px' }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr className='row_style'>
                                    <td style={{ paddingLeft: '11px', textDecoration: 'underline solid black' }}>Debit Type</td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Fixed Amount</span></td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" defaultChecked className="" /><span className='row_td_span_style'>Maximum Amount</span></td>
                                    <td style={{ paddingLeft: '7px', textDecoration: 'underline solid black' }}>Frequency</td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Monthly</span></td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Quarterly</span></td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Half Yearly</span></td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" className="" /><span className='row_td_span_style'>Yearly</span></td>
                                    <td style={{ paddingLeft: '7px' }} className='row_td_style'><input type="checkbox" defaultChecked className="" /><span className='row_td_span_style'>As & When Presented</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent" style={{ width: '90px' }}>Reference 1&nbsp;</td>
                                    <td className="table-bordered reference1" style={{ width: '40.5%', height: '28px', textAlign: 'left', paddingLeft: '10px' }}></td>
                                    <td style={{ paddingLeft: '15px', width: '90px' }}>Reference 2&nbsp;</td>
                                    <td className="table-bordered reference2" style={{ width: '40.5%', height: '28px', textAlign: 'left', paddingLeft: '10px' }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent">
                                        <p align="justify" style={{ width: '100%', letterSpacing: '0px', lineHeight: '16px', fontSize: '14px', wordSpacing: '0px' }}>
                                            1) I agree for the debit of mandate processing charges by the bank whom I am authorizing to debit my account as per latest schedule of charges of the bank.2) This is to confirm that the declaration has been carefully read, understood & made by me/us. I am authorizing the user entity/Corporate to debit my account,based on the instruction as agreed and signed by me. <br /> 3) I have understood that I am authorized to cancel/amend this mandate by appropriately communicating the cancellation/amendment request to the used entity/corporate or the bank where I have authorized the debit.
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tabledate" style={{ marginTop: '-6px' }}>
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent">
                                        FROM
                                    </td>
                                    <td style={{ paddingLeft: '5px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_0" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_1" style={{ height: '27px' }}></td>
                                    <td style={{ paddingLeft: '5px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_2" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_3" style={{ height: '27px' }}></td>
                                    <td style={{ paddingLeft: '5px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_4" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_5" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_6" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Fromdate_7" style={{ height: '27px' }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tabledate">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent">
                                        TO
                                    </td>
                                    <td style={{ paddingLeft: '26px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_0" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_1" style={{ height: '27px' }}></td>
                                    <td style={{ paddingLeft: '5px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_2" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_3" style={{ height: '27px' }}></td>
                                    <td style={{ paddingLeft: '5px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_4" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_5" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_6" style={{ height: '27px' }}></td>
                                    <td className="table-bordered SmallBoxDate Todate_7" style={{ height: '27px' }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin">
                            <tbody>
                                <tr>
                                    <td className="aligncolumnIndent" style={{ width: '55px' }}>
                                    </td>
                                    <td style={{ fontSize: '13px', paddingBottom: '7px', fontWeight: '500' }}>
                                        Maximum period of validity of this mandate <br /> is 40 years only
                                    </td>
                                    <td className="aligncolumnIndent">&nbsp;</td>
                                    <td className="Phone-Pull-tdleft" style={{ width: '25%', textDecoration: 'underline solid #808080', color: '#000 !important' }}> Signature Primary Account Holder </td>
                                    <td className="Phone-Pull-tdleft" style={{ width: '20%', textDecoration: 'underline solid #808080', color: '#000 !important' }}> Signature of Account Holder </td>
                                    <td className="Phone-Pull-tdleft" style={{ width: '20%', textDecoration: 'underline solid #808080', color: '#000 !important' }}> Signature of Account Holder </td>
                                </tr>
                                <tr>
                                    <td className="aligncolumnIndent">Phone No.</td>
                                    <td className="table-bordered Phone-td-style phoneno" style={{ paddingLeft: '10px' }}></td>
                                    <td className="aligncolumnIndent">&nbsp;</td>

                                    <td className="bankrecord-pull-left name1">
                                        <b style={{ color: 'black' }}>1. ___________________</b>

                                    </td>
                                    <td className="bankrecord-pull-left name2">
                                        <b style={{ color: 'black' }}>2. ___________________</b>
                                    </td>
                                    <td className="bankrecord-pull-left name3">
                                        <b style={{ color: 'black' }}>3. ___________________</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="tablemargin" style={{ marginTop: '0px' }}>
                        </table>

                    </div>
                </div>
            )}
        </div>
    )
};

export default AchPdf;
